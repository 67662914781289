import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { StageSize } from './App';


export function CreativeSizeEditor() {
    const [stageSize, setStageSize] = useRecoilState(StageSize);
    const [width, setWidth] = useState(stageSize.width);
    const [height, setHeight] = useState(stageSize.height);

    return (
        <div className='two-items'>
            <fieldset>
                <label>
                    Creative width:
                    <input
                        type='number'
                        value={width}
                        onChange={(e) => {
                            setWidth(parseInt(e.target.value));
                            setStageSize({ width: parseInt(e.target.value, 10), height });
                        }} />
                </label>
            </fieldset>
            <fieldset>
                <label>
                    Creative height:
                    <input
                        type='number'
                        value={height}
                        onChange={(e) => {
                            setHeight(parseInt(e.target.value));
                            setStageSize({ width, height: parseInt(e.target.value, 10) });
                        }} />
                </label>
            </fieldset>
        </div>
    );
}
