import { App } from './App'
import { createRoot } from 'react-dom/client'
import '@picocss/pico/css/pico.min.css'
import 'react-nestable/dist/styles/index.css';
import { RecoilRoot } from 'recoil'

const container = document.getElementById('app')
const root = createRoot(container)
root.render(
    <RecoilRoot>
        <App />
    </RecoilRoot>
)
