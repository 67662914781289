import { Layer } from './App';

export function createLayer(layer: Layer) {
    const div = document.createElement('div');
    div.id = layer.id;

    if (layer.width) {
        div.style.width = `${layer.width}px`;
    }

    if (layer.height) {
        div.style.height = `${layer.height}px`;
    }

    if (layer.src) {
        div.style.backgroundImage = `url(${layer.src})`;
    }

    if (layer.text) {
        div.innerText = layer.text || '';
    }

    div.style.transform = `translate(${layer.left || 0}px, ${layer.top || 0}px)`;

    if (layer.cols || layer.rows) {
        div.style.display = 'grid';
    }

    if (layer.cols) {
        div.style.gridTemplateColumns = layer.cols;
    }

    if (layer.rows) {
        div.style.gridTemplateRows = layer.rows;
    }

    if (layer.fontSize) {
        div.style.fontSize = `${layer.fontSize}px`;
    }

    if (layer.color) {
        div.style.color = layer.color;
    }

    if (layer.font) {
        div.style.fontFamily = layer.font;
    }

    if (layer.alignSelf) {
        div.style.alignSelf = layer.alignSelf;
    }

    if (layer.justifySelf) {
        div.style.justifySelf = layer.justifySelf;
    }

    if (layer.lineHeight) {
        div.style.lineHeight = `${layer.lineHeight}px`;
    }

    // yep :(
    div.layerID = layer.id;

    // if layer has children, create them
    if (layer.children) {
        layer.children.forEach((child) => {
            div.appendChild(createLayer(child));
        });
    }

    return div;
}
