import React from 'react';
import { useRecoilState } from 'recoil';
import { Creative, Layer, updateLayerData } from './App';


export function useLayerData(layerId: string) {
    const [creative, setCreative] = useRecoilState(Creative);

    const layer = React.useMemo(() => {
        function findLayer(layers: Layer[]): Layer | null {
            for (const layer of layers) {
                if (layer.id === layerId)
                    return layer;
                if (layer.children) {
                    const child = findLayer(layer.children);
                    if (child)
                        return child;
                }
            }
            return null;
        }
        return findLayer(creative);
    }, [creative, layerId]);

    const updateLayer = React.useCallback(
        (data: Partial<Layer>) => {
            setCreative((old) => updateLayerData(old, layerId, data));
        },
        [layerId, setCreative]
    );

    return [layer, updateLayer] as const;
}
