import React from 'react'
import {
    TbCarouselVertical as VerticalIcon,
    TbCarouselHorizontal as HorizontalIcon,
    TbAlignCenter,
    TbAlignLeft,
    TbAlignRight,
    TbAlignJustified,
    TbLayoutAlignBottom,
    TbLayoutAlignCenter,
    TbLayoutAlignLeft,
    TbLayoutAlignMiddle,
    TbLayoutAlignRight,
    TbLayoutAlignTop,
} from 'react-icons/tb'
import { useRecoilValue } from 'recoil'
import { SelectedLayer } from './App'
import { useLayerData } from './useLayerData'

export function LayerPropertyEditor() {
    const selectedLayer = useRecoilValue(SelectedLayer)
    const [layer, setLayer] = useLayerData(selectedLayer?.id || '')

    if (!selectedLayer || !layer) {
        return null
    }

    const intOrNull = (value: string) => {
        const parsed = parseInt(value, 10)
        return isNaN(parsed) ? null : parsed
    }

    const setVertical = () => {
        setLayer({ rows: layer.rows || layer.cols || 'repeat(auto)', cols: undefined })
    }

    const setHorizontal = () => {
        setLayer({ cols: layer.cols || layer.rows || 'repeat(auto)', rows: undefined })
    }

    const isVertical = layer.rows && !layer.cols

    const toggleAlignSelf = (alignSelf: 'start' | 'center' | 'end' | null) => {
        if (alignSelf === layer.alignSelf) {
            setLayer({ alignSelf: null })
        } else {
            setLayer({ alignSelf })
        }
    }

    const toggleJustifySelf = (justifySelf: 'start' | 'center' | 'end' | null) => {
        if (justifySelf === layer.justifySelf) {
            setLayer({ justifySelf: null })
        } else {
            setLayer({ justifySelf })
        }
    }

    return (
        <div className='layer-property-editor'>
            <div className='two-items first-is-smaller'>
                <fieldset>
                    <label>
                        Layer ID:
                        <input readOnly value={layer.id} />
                    </label>
                </fieldset>
                <fieldset>
                    <label>
                        Layer name:
                        <input
                            value={layer.title}
                            onChange={(e) => {
                                setLayer({ title: e.target.value })
                            }}
                        />
                    </label>
                </fieldset>
            </div>
            <div className='two-items'>
                <fieldset style={{ marginBottom: '0' }}>
                    <label>
                        Left (points):
                        <input
                            value={layer.left || ''}
                            onChange={(e) => {
                                setLayer({ left: intOrNull(e.target.value) })
                            }}
                        />
                    </label>
                </fieldset>
                <fieldset style={{ marginBottom: '0' }}>
                    <label>
                        Top (points):
                        <input
                            value={layer.top?.toString() || ''}
                            onChange={(e) => {
                                setLayer({ top: intOrNull(e.target.value) })
                            }}
                        />
                    </label>
                </fieldset>
            </div>
            <div className='two-items'>
                <fieldset style={{ marginBottom: '0' }}>
                    <label>
                        Width (points):
                        <input
                            value={layer.width || ''}
                            onChange={(e) => {
                                setLayer({ width: intOrNull(e.target.value) })
                            }}
                        />
                    </label>
                </fieldset>
                <fieldset style={{ marginBottom: '0' }}>
                    <label>
                        Height (points):
                        <input
                            value={layer.height || ''}
                            onChange={(e) => {
                                setLayer({ height: intOrNull(e.target.value) })
                            }}
                        />
                    </label>
                </fieldset>
            </div>

            <div className='two-items'>
                <button className={isVertical ? '' : 'outline'} onClick={() => setVertical()}>
                    <VerticalIcon size={24} /> Vertical
                </button>

                <button className={isVertical ? 'outline' : ''} onClick={() => setHorizontal()}>
                    <HorizontalIcon size={24} /> Horizontal
                </button>
            </div>
            <fieldset style={{ marginBottom: '0' }}>
                <label>
                    Distribution:
                    <input
                        value={layer.rows || layer.cols || ''}
                        onChange={(e) => {
                            if (isVertical) {
                                setLayer({ rows: e.target.value })
                            } else {
                                setLayer({ cols: e.target.value })
                            }
                        }}
                    />
                </label>
            </fieldset>


            {isVertical && (
                <div className='three-items'>
                    <button
                        onClick={() => toggleAlignSelf('start')}
                        className={layer.alignSelf === 'start' ? '' : 'outline'}
                    >
                        <TbLayoutAlignTop />
                    </button>
                    <button
                        onClick={() => toggleAlignSelf('center')}
                        className={layer.alignSelf === 'center' ? '' : 'outline'}
                    >
                        <TbLayoutAlignCenter />
                    </button>
                    <button
                        onClick={() => toggleAlignSelf('end')}
                        className={layer.alignSelf === 'end' ? '' : 'outline'}
                    >
                        <TbLayoutAlignBottom />
                    </button>
                </div>
            )}

            {!isVertical && (
                <div className='three-items'>
                    <button
                        onClick={() => toggleAlignSelf('start')}
                        className={layer.alignSelf === 'start' ? '' : 'outline'}
                    >
                        <TbLayoutAlignLeft />
                    </button>
                    <button
                        onClick={() => toggleAlignSelf('center')}
                        className={layer.alignSelf === 'center' ? '' : 'outline'}
                    >
                        <TbLayoutAlignMiddle />
                    </button>
                    <button
                        onClick={() => toggleAlignSelf('end')}
                        className={layer.alignSelf === 'end' ? '' : 'outline'}
                    >
                        <TbLayoutAlignRight />
                    </button>
                </div>
            )}

            <div className='three-items'>
                <button
                    onClick={() => toggleJustifySelf('start')}
                    className={layer.justifySelf === 'start' ? '' : 'outline'}
                >
                    <TbAlignLeft />
                </button>
                <button
                    onClick={() => toggleJustifySelf('center')}
                    className={layer.justifySelf === 'center' ? '' : 'outline'}
                >
                    <TbAlignCenter />
                </button>
                <button
                    onClick={() => toggleJustifySelf('end')}
                    className={layer.justifySelf === 'end' ? '' : 'outline'}
                >
                    <TbAlignRight />
                </button>
            </div>

            <fieldset>
                <label>
                    Text:
                    <input
                        value={layer.text || ''}
                        onChange={(e) => {
                            setLayer({ text: e.target.value })
                        }}
                    />
                </label>
            </fieldset>
            <div className='two-items'>
                <fieldset>
                    <label>
                        Font:
                        <input
                            value={layer.font || ''}
                            onChange={(e) => {
                                setLayer({ font: e.target.value })
                            }}
                        />
                    </label>
                </fieldset>
                <fieldset>
                    <label>
                        Size:
                        <input
                            value={layer.fontSize || ''}
                            onChange={(e) => {
                                setLayer({ fontSize: intOrNull(e.target.value) })
                            }}
                        />
                    </label>
                </fieldset>
            </div>

            <div className='two-items'>
                <fieldset>
                    <label>
                        Line height:
                        <input
                            value={layer.lineHeight || ''}
                            onChange={(e) => {
                                setLayer({ lineHeight: intOrNull(e.target.value) })
                            }}
                        />
                    </label>
                </fieldset>
                <fieldset>
                    <label>
                        Color:
                        <input
                            value={layer.color || ''}
                            onChange={(e) => {
                                setLayer({ color: e.target.value })
                            }}
                        />
                    </label>
                </fieldset>
            </div>
            <fieldset>
                <label>
                    Image URL:
                    <input
                        value={layer.src || ''}
                        onChange={(e) => {
                            setLayer({ src: e.target.value })
                        }}
                    />
                </label>
            </fieldset>
        </div>
    )
}
