import React from 'react';
import { PlusIcon, Bars3Icon as HandleIcon } from '@heroicons/react/24/outline';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Creative, Layer, SelectedLayer, StageSize } from './App';
import Nestable from 'react-nestable';
import { nextLayerId } from './Editor';


export function LayerList() {
    const [creative, setCreative] = useRecoilState(Creative);
    const [selectedLayer, setSelectedLayer] = useRecoilState(SelectedLayer);
    const stageSize = useRecoilValue(StageSize);

    const newLayer = () => {
        const id = nextLayerId();
        const layer: Layer = {
            id,
            title: `Layer ${id}`,
            text: `Layer ${id}`,
            left: 0,
            top: 0,
            width: stageSize.width,
            height: stageSize.height,
        };
        setCreative([...creative, layer]);
    };

    const renderLayer = ({ item, handler }) => {
        return (
            <div
                style={{
                    border: '1px solid gray',
                    borderRadius: '0.4rem',
                    background: item.id === selectedLayer?.id ? 'lightGray' : 'transparent',
                }}
                onClick={() => {
                    setSelectedLayer(item);
                }}
            >
                {handler} {item.title}
            </div>
        );
    };

    const updateLayers = (props: { items: any; }) => {
        const { items } = props;
        setCreative(items);
    };

    return (
        <div>
            <div>
                <PlusIcon height={16} width={16} onClick={() => newLayer()} />
            </div>
            <div style={{ height: '20rem', overflow: 'scroll' }}>
                <Nestable
                    handler={<HandleIcon height={14} width={16} style={{ marginTop: '-2px' }} />}
                    className='nested-item'
                    threshold={16}
                    items={creative}
                    renderItem={renderLayer}
                    onChange={updateLayers} />
            </div>
        </div>
    );
}
