import React from 'react'
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Editor } from './Editor'
import { createLayer } from './createLayer'

export type Layer = {
    id: string
    title: string
    src?: string
    text?: string
    cols?: string
    rows?: string
    fontSize?: number | null
    color?: string | null
    font?: string | null
    left: number | null
    top: number | null
    width: number | null
    height: number | null
    alignSelf?: 'start' | 'center' | 'end' | null
    justifySelf?: 'start' | 'center' | 'end' | null
    lineHeight?: number | null
    children?: Layer[]
}

export const Creative = atom<Layer[]>({ default: [], key: 'Creative' })
export const StageSize = atom<{ width: number; height: number }>({
    default: { width: 320, height: 250 },
    key: 'StageSize',
})

export const SelectedLayer = atom<Layer | null>({ default: null, key: 'SelectedLayer' })

const EditorHTML = selector({
    key: 'EditorHTML',
    get: ({ get }) => {
        console.log('rewriting html')
        const creative = get(Creative)
        const stageSize = get(StageSize)
        const mainEditor = document.getElementById('main-editor')!
        mainEditor.style.width = `${stageSize.width}px`
        mainEditor.style.height = `${stageSize.height}px`

        while (mainEditor.firstChild) {
            mainEditor.removeChild(mainEditor.firstChild)
        }

        creative.forEach((layer) => {
            mainEditor.appendChild(createLayer(layer))
            layer.extra = 'extra'
        })

        return mainEditor
    },
})

export function updateLayerData(collection: Layer[], layerId: string, data: Partial<Layer>): Layer[] {
    return collection.map((layer) => {
        if (layer.id === layerId) {
            return { ...layer, ...data }
        }
        if (layer.children) {
            return { ...layer, children: updateLayerData(layer.children, layerId, data) }
        }
        return layer
    })
}

export function App() {
    // TODO: remove the findLayer and changeLayerProps functions and use the hooks instead

    const stage = React.useRef<HTMLElement>(null)
    const stageHTML = useRecoilValue(EditorHTML)
    const [creative, setCreative] = useRecoilState(Creative)
    const setSelectedLayer = useSetRecoilState(SelectedLayer)

    React.useEffect(() => {
        let stageDiv = stage.current
        if (!stageDiv) {
            stageDiv = document.getElementById('ctf-stage')!
            stage.current = stageDiv
        }
        //stageDiv.innerHTML = stageHTML
    }, [])

    const findLayer = React.useMemo(() => {
        return (layerId: string) => {
            function findLayer(layers: Layer[]): Layer | null {
                for (const layer of layers) {
                    if (layer.id === layerId) return layer
                    if (layer.children) {
                        const child = findLayer(layer.children)
                        if (child) return child
                    }
                }
                return null
            }
            return findLayer(creative)
        }
    }, [creative])

    const selectLayer = (layerId: string) => {
        const layer = findLayer(layerId)
        if (!layer) return
        console.log('selecting layer', layer)
        setSelectedLayer(layer)
    }

    const changeLayerProps = (layerId: string, props: Partial<Layer>) => {
        console.log("layer's props changed", layerId, props.left)
        setCreative((old) => updateLayerData(old, layerId, props))
    }

    React.useEffect(() => {
        const container = document.getElementById('main-editor')!
        let selectedDiv: HTMLElement | null = null
        let shiftX, shiftY

        container.onpointerdown = function (event) {
            if (event.shiftKey) return
            let target = event.target?.closest('#main-editor > div') // Get the closest 'div' element from the event target

            //      if (!target || !container.contains(target)) return; // If there's no 'div' or the 'div' is not within the container, return
            if (!target) return

            selectedDiv = target

            // get the the translation of the selected div
            const transform = selectedDiv?.style.transform || 'translate(0px, 0px)'
            const translate = transform.match(/translate\((.*)px, (.*)px\)/)
            const translateX = translate ? parseInt(translate[1]) : 0
            const translateY = translate ? parseInt(translate[2]) : 0

            shiftX = event.clientX - translateX // - selectedDiv!.getBoundingClientRect().left;
            shiftY = event.clientY - translateY //- selectedDiv!.getBoundingClientRect().top;
            selectedDiv?.classList.add('selected')
            selectLayer(selectedDiv.layerID)
        }

        container.onpointermove = function (event) {
            if (!selectedDiv) return // If no div is selected, return
            let newLeft = event.clientX - shiftX
            let newTop = event.clientY - shiftY

            selectedDiv.style.transform = `translate(${newLeft}px, ${newTop}px)`
        }

        container.onpointerup = function () {
            if (!selectedDiv || !selectedDiv.layerID) return 
            
            const transform = selectedDiv?.style.transform || 'translate(0px, 0px)'
            const translate = transform.match(/translate\((.*)px, (.*)px\)/)
            const translateX = translate ? parseInt(translate[1]) : 0
            const translateY = translate ? parseInt(translate[2]) : 0
            changeLayerProps(selectedDiv.layerID, { left: translateX, top: translateY })
            selectedDiv?.classList.remove('selected')
            selectedDiv = null // Deselect the div
        }

        container.ondragstart = function () {
            return false // Prevent default dragstart action
        }
    }, [creative, changeLayerProps, stageHTML])

    return (
        <>
            <Editor />
        </>
    )
}
