import React from 'react'
import { CreativeSizeEditor } from './CreativeSizeEditor'
import { LayerList } from './LayerList'

import { LayerPropertyEditor } from './LayerPropertyEditor'

let lastLayerId = 0
export const nextLayerId = () => {
    lastLayerId += 1
    return lastLayerId.toString()
}

export function Editor() {
    return (
        <>
            <CreativeSizeEditor />
            <LayerList />
            <LayerPropertyEditor />
        </>
    )
}
